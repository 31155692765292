import { getDefaultConfig } from "@rainbow-me/rainbowkit";

import { http, fallback } from "wagmi";
import { holesky } from "wagmi/chains";

const projectId = "83e368e3774a002470a0c705886e8ef7";

export const config = getDefaultConfig({
  appName: "JOOCE",
  projectId,
  chains: [holesky],
  transports: {
    [holesky.id]: fallback([
      http("https://1rpc.io/holesky"),
      http("https://ethereum-holesky-rpc.publicnode.com"),
      http("https://ethereum-holesky.blockpi.network/v1/rpc/public"),
    ]),
  },
  ssr: true,
});

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}
