import type { FC, PropsWithChildren } from "react";
import React, { useMemo, useState } from "react";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  BitgetWalletAdapter,
  BitpieWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  MathWalletAdapter,
  PhantomWalletAdapter,
  SafePalWalletAdapter,
  SolongWalletAdapter,
  TokenPocketWalletAdapter,
  TorusWalletAdapter,
  TrustWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

const endpoint = clusterApiUrl(WalletAdapterNetwork.Mainnet);
const wallets = [
  new PhantomWalletAdapter(),
  new TorusWalletAdapter(),
  new TrustWalletAdapter(),
  new MathWalletAdapter({ endpoint }),
  new TokenPocketWalletAdapter(),
  new CoinbaseWalletAdapter({ endpoint }),
  new SolongWalletAdapter({ endpoint }),
  new Coin98WalletAdapter({ endpoint }),
  new SafePalWalletAdapter({ endpoint }),
  new BitpieWalletAdapter({ endpoint }),
  new BitgetWalletAdapter({ endpoint }),
];

export const SolanaWalletProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  return (
    <ConnectionProvider
      endpoint={endpoint}
      config={{ disableRetryOnRateLimit: true }}
    >
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
