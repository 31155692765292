import type { DeepPartial } from "@/types/deep-partial";

export const featureFlags: DeepPartial<{
  connectWallet: {};
  pages: {
    index: {};
    presale: {
      info: {};
      community: {
        custom: {};
        claim: {};
      };
      form: {
        maxButton: {};
        receiveSimulation: {};
      };
      leaderboard: {};
    };
    airdrop: {
      info: {};
      form: {};
    };
    locker: {};
    gauges: {};
    stats: {};
  };
}> = {} as const;
