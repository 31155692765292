import type { QueryClient } from "@tanstack/react-query";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { WagmiProvider } from "wagmi";

import ErrorBoundary from "@/components/error-boundary";
import { SiteHeader } from "@/components/site-header";
import { TailwindIndicator } from "@/components/tailwind-indicator";
import { Toaster } from "@/components/ui/toast/toaster";
import { config } from "@/configs/wagmi-config";
import { SolanaWalletProvider } from "@/providers/SolanaWalletProvider";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: () => (
    <WagmiProvider config={config}>
      <RainbowKitProvider
        showRecentTransactions
        theme={darkTheme({
          accentColor: "#fdca12",
          accentColorForeground: "black",
          overlayBlur: "small",
        })}
      >
        <SolanaWalletProvider>
          <ErrorBoundary>
            <SiteHeader />
            <div className="min-h-screen">
              <Outlet />
            </div>
            {/* <SiteFooter /> */}
            <TailwindIndicator />
            <Toaster />
          </ErrorBoundary>
        </SolanaWalletProvider>
      </RainbowKitProvider>
    </WagmiProvider>
  ),
});
