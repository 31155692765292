import * as Sentry from "@sentry/react";
import type { PropsWithChildren } from "react";
import { useAccount } from "wagmi";

export default function ErrorBoundary({
  children,
}: PropsWithChildren): JSX.Element {
  const { chainId, status, address } = useAccount();

  return (
    <Sentry.ErrorBoundary
      fallback={() => <>sorry, smth went wrong</>}
      beforeCapture={(scope) => {
        scope.setLevel("fatal");
        if (status === "connected") {
          scope.setTag("chain_id", chainId);
          scope.setTag("address", address);
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
