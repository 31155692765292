import { Link } from "@tanstack/react-router";
import { type Variants, motion } from "framer-motion";
import {
  BicepsFlexed,
  ChartSpline,
  Droplets,
  ShoppingCart,
  CoinsIcon,
} from "lucide-react";
import type React from "react";

import { featureFlags } from "@/configs/feature-flags";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { useState } from "react";

const navVariants: Variants = {
  hidden: { opacity: 0, scale: 0.95 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delayChildren: 0.1,
      staggerChildren: 0.2,
    },
  },
};

const navChildrenVariants: Variants = {
  hidden: { opacity: 0, scale: 0.95 },
  show: { opacity: 1, scale: 1 },
};

const navLinks = [
  ...(featureFlags?.pages?.presale
    ? [
        {
          to: "/presale",
          icon: <ShoppingCart size={16} />,
          label: "Presale",
          avaliable: false,
        },
      ]
    : []),
  ...(featureFlags?.pages?.airdrop
    ? [
        {
          to: "/airdrop",
          icon: <CoinsIcon size={16} />,
          label: "Airdrop",
          avaliable: false,
        },
      ]
    : []),
  ...(featureFlags?.pages?.locker
    ? [
        {
          to: "/locker",
          icon: <BicepsFlexed size={16} />,
          label: "Locker",
          avaliable: false,
        },
      ]
    : []),
  ...(featureFlags?.pages?.gauges
    ? [
        {
          to: "/gauges",
          icon: <Droplets size={16} />,
          label: "Gauges",
          avaliable: false,
        },
      ]
    : []),
  ...(featureFlags?.pages?.stats
    ? [
        {
          to: "/",
          icon: <ChartSpline size={16} />,
          label: "Stats",
          avaliable: false,
        },
      ]
    : []),
];

export const SiteHeader: React.FC = () => {
  const [isTriggered,setTriggered] = useState(false)
  const handleTrigger = () => {
    setTriggered(true)
    setTimeout(()=>{
      setTriggered(false)
    },1000)
  }
  return (
    <TooltipProvider disableHoverableContent={true} delayDuration={100} >
      <header className="absolute inset-x-0 top-0 mx-auto p-4 md:p-6 z-50">
        <div onClick={handleTrigger} onPointerOut={()=>setTriggered(false)} onTouchEnd={()=>setTriggered(false)} onMouseOver={()=>setTriggered(true)} onMouseLeave={()=>setTriggered(false)} className="flex items-center justify-start space-x-3 sm:space-x-6 w-[40px]">
          <Tooltip open={isTriggered}>
            <TooltipTrigger >
              <a
                href="/"
                target="_blank"
                className="bg-white/50 p-2 rounded-xl size-10 flex items-center justify-center pointer-events-none"
                rel="noreferrer"
                
              >
                <span className="sr-only">Telegram</span>
                <svg
                  height="32"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z" />
                </svg>
              </a>
            </TooltipTrigger>
            <TooltipContent>
              <p>Coming Soon</p>
            </TooltipContent>
          </Tooltip>
        </div>
        
        {/* <motion.nav
          className="h-11 flex justify-center items-center space-x-1 sm:place-self-center bg-white/50 row-start-2 col-span-3 md:row-start-1 md:col-span-1 md:col-start-2 rounded-2xl has-[div]:p-1"
          variants={navVariants}
          initial="hidden"
          animate="show"
        >
          {navLinks.map((link) => {
            if (link.avaliable)
              return (
                <motion.div
                  key={link.to}
                  variants={navChildrenVariants}
                  className="size-full"
                >
                  <Link
                    to={link.to}
                    className="size-full flex justify-center items-center font-medium rounded-xl px-2 hover:underline underline-offset-2 transition-colors relative"
                  >
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <motion.div
                            className="absolute inset-0 bg-white/25 rounded-xl"
                            layoutId="currentTab"
                          />
                        )}
                        <motion.div whileHover={{ rotate: 15 }}>
                          {link.icon}
                        </motion.div>
                        <span className="text-xs sm:text-sm ml-2">
                          {link.label}
                        </span>
                      </>
                    )}
                  </Link>
                </motion.div>
              );

            return (
              <Tooltip>
                <TooltipTrigger>
                  <motion.div
                    key={link.to}
                    variants={navChildrenVariants}
                    className="size-full"
                  >
                    <Link
                      to="/"
                      className="size-full flex justify-center items-center font-medium rounded-xl px-2 hover:underline underline-offset-2 transition-colors relative"
                    >
                      <motion.div whileHover={{ rotate: 15 }}>
                        {link.icon}
                      </motion.div>
                      <span className="text-xs sm:text-sm ml-2">
                        {link.label}
                      </span>
                    </Link>
                  </motion.div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Coming Soon</p>
                </TooltipContent>
              </Tooltip>
            );
          })}
        </motion.nav> */}

        <div className="flex justify-end items-center space-x-2 col-span-2 md:col-span-1">
          {/* <ConnectButton
                label="Connect"
                showBalance={false}
                accountStatus="avatar"
                chainStatus="icon"
              /> */}
        </div>
      </header>
    </TooltipProvider>
  );
};
